import { Trans } from '@lingui/macro';
import { NoddiBasicCard } from 'noddi-ui';
import { NoddiDummyButton } from 'noddi-ui-common';
import { cn } from 'noddi-ui/src/helpers/utils';
import CarItemCardSvg from '../../../../components/Home/CarSvg';

type CarCardProps = {
  title: string;
  description?: string;
  isSelected: boolean;
  action: () => void;
};

export const SelectCarInBookingCard = ({ title, isSelected, description, action }: CarCardProps) => {
  return (
    <NoddiBasicCard
      onClick={action}
      className={cn('flex min-w-75 justify-between gap-4', isSelected && 'bg-primary-purple text-primary-white')}
    >
      <div className='flex items-center gap-4'>
        <div>
          <CarItemCardSvg scale={0.5} />
        </div>
        <div>
          <p className='font-semibold'>{title}</p>
          {description && <p>{description}</p>}
        </div>
      </div>
      <NoddiDummyButton
        startIcon={isSelected ? 'Minus' : 'Plus'}
        className='!text-primary-black'
        variant='secondary'
        size='small'
      >
        {isSelected ? <Trans>Remove </Trans> : <Trans>Add </Trans>}
      </NoddiDummyButton>
    </NoddiBasicCard>
  );
};
