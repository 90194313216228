import { type VariantProps } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

import { ActivityIndicator, Text, View } from 'react-native';
import { NoddiIcon } from '../atoms';
import { IconName } from '../types/icons';
import { cn } from '../utils';
import { buttonVariants, iconColorVariantsInNoddiButton, loadingVariants, textVariants } from './NoddiButton';

type NoddiDummyButtonProps = VariantProps<typeof buttonVariants> & {
  loading?: boolean;
  className?: string;
} & ({ startIcon?: IconName; endIcon?: never } | { endIcon?: IconName; startIcon?: never });

export const NoddiDummyButton = ({
  className,
  variant,
  size,
  endIcon,
  startIcon,
  loading,
  children
}: PropsWithChildren<NoddiDummyButtonProps>) => {
  const iconSize = size === 'small' ? 'small' : 'medium';
  const buttonClassName = cn(buttonVariants({ variant, size }), className);
  const textClassName = cn(textVariants({ variant }));
  const iconColor = iconColorVariantsInNoddiButton({ variant });
  const loadingColor = loadingVariants({ variant });

  return (
    <View accessible className={buttonClassName}>
      {loading ? (
        <View className='flex items-center justify-center'>
          <ActivityIndicator size='small' color={loadingColor} />
        </View>
      ) : (
        <View className='flex flex-row items-center gap-2'>
          {startIcon && (
            <View>
              <NoddiIcon color={iconColor} name={startIcon} size={iconSize} />
            </View>
          )}
          <Text className={textClassName}>{children}</Text>
          {endIcon && (
            <View>
              <NoddiIcon color={iconColor} name={endIcon} size={iconSize} />
            </View>
          )}
        </View>
      )}
    </View>
  );
};
