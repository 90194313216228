import { t, Trans } from '@lingui/macro';
import { BookingDataHome, BookingDataHomeBase } from 'noddi-async/src/types';
import { colors, NoddiBasicCard, NoddiChip, NoddiIcon } from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';
import { useNavigate } from 'react-router-dom';

import { TZDate } from '@date-fns/tz';
import { NoddiDummyButton } from 'noddi-ui-common';
import routes from '../../appRoutes';

type BookingCardProps = {
  booking: BookingDataHome | BookingDataHomeBase;
  isUpcomingBooking?: boolean;
};

const BookingCard = ({ booking, isUpcomingBooking }: BookingCardProps) => {
  const navigate = useNavigate();

  const { address, timeWindow, bookingItems } = booking;
  // Todo : date should be based on local timezone from address : clickup id : CU-86c1jvzb6
  const bookingTime = `${format(new TZDate(timeWindow.startsAtUtc), DateFormats.TIME)} - ${format(timeWindow.endsAtUtc, DateFormats.TIME)}`;
  const bookingDate = `${format(timeWindow.endsAtUtc, DateFormats.FULL_WEEKDAY_MONTH_AND_YEAR)}`;

  const getPaymentStatusChip = () => {
    const bookingData = booking as BookingDataHome;
    if (bookingData.isPaid) {
      return <NoddiChip variant='success' label={t`Paid`} />;
    } else if (bookingData.isInvoiced) {
      return <NoddiChip variant='warning' label={t`Invoiced`} />;
    } else {
      return <NoddiChip variant='destructive' label={t`Unpaid`} />;
    }
  };

  return (
    <NoddiBasicCard
      onClick={() => navigate(routes.myBookingDetails.getPath({ id: booking.id }))}
      className='relative flex flex-col gap-4'
    >
      {!isUpcomingBooking && (
        <div className='absolute right-5 top-4 flex justify-end gap-2'>{getPaymentStatusChip()}</div>
      )}
      <div className='flex flex-col gap-2'>
        {bookingItems.map(({ car: { make, model, licensePlateNumber }, orderLines }, bookingItemIdx) => (
          <div key={bookingItemIdx} className='flex flex-col gap-2'>
            <div>
              <p className='mr-22 font-bold text-5'>{`${make} ${model}`}</p>
              <p className='text-5'>{licensePlateNumber}</p>
            </div>

            <div className='flex flex-wrap gap-x-2'>
              {orderLines.map(({ description }, serviceIndex) => (
                <span key={licensePlateNumber + serviceIndex}>
                  {description}
                  {serviceIndex < orderLines.length - 1 && ','}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
      {isUpcomingBooking && (
        <div className='flex flex-col gap-1'>
          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon color={colors.primary.darkPurple} name='ClockCircle' />
            </div>
            <p>{bookingTime}</p>
          </div>
          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon color={colors.primary.darkPurple} name='Calendar' />
            </div>
            <p className='capitalize'>{bookingDate}</p>
          </div>
          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon name='LocationPin' color={colors.primary.darkPurple} />
            </div>
            <p>
              {address.streetName} {address.streetNumber}
            </p>
          </div>
        </div>
      )}

      {!isUpcomingBooking && (
        <div className='flex items-center gap-2'>
          <div>
            <NoddiIcon color={colors.primary.darkPurple} name='Calendar' />
          </div>
          <p className='capitalize'>{bookingDate}</p>
        </div>
      )}

      <div className='flex h-full items-end justify-end'>
        <NoddiDummyButton variant='link' className='pb-0 pr-0' endIcon='ArrowRight'>
          <Trans>See booking</Trans>
        </NoddiDummyButton>
      </div>
    </NoddiBasicCard>
  );
};

export default BookingCard;
